import { useCallback, useState } from "react";

import { PREVIEW } from "@/config";

export const usePost = <T extends object>(url: string) => {
	const [data, setData] = useState<T | null>(null);
	const [error, setError] = useState<Error | null>(null);
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	const post = useCallback(
		async data => {
			setLoading(true);
			setSuccess(false);

			if (!PREVIEW) {
				return fetch(url, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(data),
				})
					.then(response => {
						return response.json();
					})
					.then(data => {
						setData(data);
						setSuccess(true);

						return data;
					})
					.catch(error_ => {
						setError(error_);
						setSuccess(false);

						return error_;
					})
					.finally(() => {
						setLoading(false);
					});
			}
		},
		[url]
	);

	return {
		data,
		error,
		loading,
		success,
		post,
	};
};
