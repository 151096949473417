import React from "react";

import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";

import { FormType } from "@/components/forms/";
import { ContactForm } from "@/components/forms/contact-form";
import { Column, Hidden, Row } from "@/components/grid";
import { Snackbar } from "@/components/snackbar";
import { Typography } from "@/components/typography/typography";
import { useFormIntersectionContext } from "@/hooks/intersection";
import { useScrollToWithHeader } from "@/hooks/scroll";
import { TypographyVariant } from "@/theme";
import type { ContactForm as ContactFormProps, TextCollection } from "@/types/contentful-api";

export const StyledHeadline = styled(Typography)`
	display: flex;
	align-items: center;
`;

export const ContactFormSlot: React.FC<{ textCollection: TextCollection } & ContactFormProps> = ({
	entryLink,
	formType,
	headline,
	textCollection,
}) => {
	const { t } = useTranslation(["forms"]);
	const buttonLabel = t("forms:contact-now");
	const { intersecting: formIntersecting, intersectingElement } = useFormIntersectionContext();
	const scrollTo = useScrollToWithHeader("contact-form");

	return (
		<>
			<ContactForm
				contactDetails={entryLink}
				type={formType as FormType}
				textCollection={textCollection}
			/>
			{formType !== FormType.CONTACT_NO_SNACKBAR && (
				<>
					<Hidden s m>
						<Snackbar
							buttonLabel={buttonLabel}
							handleClick={scrollTo}
							showSnackbar={!formIntersecting && !intersectingElement}
						/>
					</Hidden>
					<Hidden l>
						<Snackbar
							arrowIcon
							buttonLabel={buttonLabel}
							handleClick={scrollTo}
							showSnackbar={!formIntersecting}
						>
							<Row>
								<Column flex l={6}>
									<StyledHeadline
										tight
										component="h4"
										variant={TypographyVariant.headlineSansXXS}
									>
										{headline}
									</StyledHeadline>
								</Column>
							</Row>
						</Snackbar>
					</Hidden>
				</>
			)}
		</>
	);
};
